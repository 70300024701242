<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  type ContractCategory,
  ContractCategoryCollection,
} from "@planetadeleste/vue-mc-gw";

@Component({})
export default class ContractCategorySelect extends Vue {
  @VModel({ type: Number }) iCategoryId!: number;
  @Prop({ type: String, default: "contract.category" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly autoassign!: boolean;

  obCollection = new ContractCategoryCollection();

  get items(): ContractCategory[] {
    return this.obCollection.models;
  }

  get loading(): boolean {
    return this.obCollection.loading;
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    if (this.obCollection.isEmpty()) {
      await this.obCollection.list();
    }

    if (this.autoassign && !this.iCategoryId && this.obCollection.length) {
      const obModel = this.obCollection.first();
      if (obModel) {
        this.$emit("input", obModel.id);
      }
    }
  }

  onChange(iValue: number) {
    const obModel = this.obCollection.find({ id: iValue });
    this.$emit("change", obModel);
  }
}
</script>

<template>
  <ValidationProvider
    ref="fieldContractCategory"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iCategoryId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :menu-props="{ offsetY: true }"
        :success="valid"
        clearable
        dense
        hide-details="auto"
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      />
    </template>
  </ValidationProvider>
</template>
